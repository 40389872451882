import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Metadata from "../components/metadata"
import beachNewsStyles from "./beachNews.module.scss"

const BeachNews = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                featured {
                  childImageSharp {
                    fluid(maxWidth: 750) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              timeToRead
              excerpt
              id
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Metadata
        title="Beach News"
        description="Curated local and global news that is relevant to the health and enjoyment of Long Island’s beaches and the oceans at large."
      />
      <ul className={beachNewsStyles.posts}>
        {data.allMarkdownRemark.edges.map(edge => {
          return (
            <li className={beachNewsStyles.post} key={edge.node.id}>
              <h2>
                <Link to={`/beach-news/${edge.node.fields.slug}/`}>
                  {edge.node.frontmatter.title}
                </Link>
              </h2>
              <div className={beachNewsStyles.meta}>
                <span>
                  Posted on {edge.node.frontmatter.date} <span> / </span>{" "}
                  {edge.node.timeToRead} min read
                </span>
              </div>
              {edge.node.frontmatter.featured && (
                <Img
                  className={beachNewsStyles.featured}
                  fluid={edge.node.frontmatter.featured.childImageSharp.fluid}
                  alt={edge.node.frontmatter.title}
                />
              )}
              <p className={beachNewsStyles.excerpt}>{edge.node.excerpt}</p>
              <div className={beachNewsStyles.button}>
                <Link to={`/beach-news/${edge.node.fields.slug}/`}>
                  Read More
                </Link>
              </div>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

export default BeachNews